<template>
<div class="sugotoku-top" id="js_sugotoku-top_container">
	<div class="sugotoku-top-container">
		<article>
			<h1 class="about-header-small"><img class="about-header-small__img" src="@/assets/img/about_header_img02.png" alt="頭痛ーる 気圧予報で体調管理" /></h1>
			<nav class="about-header-nav">
				<ul class="about-header-nav__list">
					<li class="about-header-nav__item">
						<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingSugotokuHeaderNavi01_iOS" class="about-header-nav__item--link"><i class="about-header-nav__item--icon apl" title="アプリ" /></a>
					</li>
					<li class="about-header-nav__item">
						<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingSugotokuHeaderNavi02_iOS" class="about-header-nav__item--link"><i class="about-header-nav__item--icon graph" title="気圧グラフ" /></a>
					</li>
					<li class="about-header-nav__item">
						<a href="javascript:void(0);" v-scroll-to="'#js_scroll_shindan'" id="trackingSugotokuHeaderNavi03" class="about-header-nav__item--link"><i class="about-header-nav__item--icon shindan" title="診断" /></a>
					</li>
					<li class="about-header-nav__item">
						<a href="javascript:void(0);" v-scroll-to="'#js_scroll_column'" id="trackingSugotokuHeaderNavi04" class="about-header-nav__item--link"><i class="about-header-nav__item--icon column" title="コラム" /></a>
					</li>
				</ul>
				<div class="sugotoku-top__app-info">
					<!-- NOTE: お知らせリンクあり -->
					<!--
					<a v-if="infoDisplayFlag" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=info` } })" class="sugotoku-top__app-info--link">
						痛み記録とプロフィール機能をご利用のお客様へ
					</a>
					-->
					<!-- NOTE: お知らせリンクなし -->
					<p v-if="infoDisplayFlag" class="sugotoku-top__app-info--notice">
						いつも頭痛ーるをご利用頂きありがとうございます。<br>
						<!-- TODO: 文言をDにもらう -->
						2025年〇月〇日をもって「気圧グラフ」「全国マップ」「気圧予報」「健康天気指数」についてサービスの提供を終了とさせていただきました。<br>
						引き続き、上記の機能をご利用の方は、<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" >アプリ</a>をご利用ください。<br>
						ご利用の皆様には大変ご迷惑をおかけ致しますが、よろしくお願いいたします。
					</p>
				</div>
			</nav>

			<!-- 広告商材特集バナー -->
			<!-- <aside class="ad-top-bnr">
				<a class="ad-top-bnr__link gtm-link_click_count01" data-gtm-event-cat-label="TOPページ広告商材特集バナー_iOS" data-gtm-event-act-label="trackingTopAdBnr_iOS" href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })"><img src="@/assets/img/selfcheck_temperature_difference_bnr@2x.png" alt="医師監修 寒暖差疲労セルフチェック" /></a>
			</aside> -->
			<!-- /広告商材特集バナー -->

			<!-- 天気予報(気圧予報、健康天気指数) -->
			<!-- sugotoku-top-weather-forecast / -->
			<!-- /天気予報(気圧予報、健康天気指数) -->

			<sugotoku-top-banner />

			<!-- NOTE: グロースハック案件F 機能説明をマルっと削除して見せ方を変える（別パターン）の設置箇所 -->
			<section class="growthhack-f">
				<h2 class="growthhack-f__title">
					<i class="growthhack-f__title--img sprite_growthhack-f img01" />
					<p class="growthhack-f__title--text">あなたの<span class="is-large">その不調、<br>低気圧のせい</span>かも!?</p>
				</h2>
				<div class="growthhack-f__symptoms">
					<img src="@/assets/img/growthhack/about_intro_img01.png" alt="頭痛 落ち込み 肩こり だるさ むくみ" class="growthhack-f__symptoms--img">
				</div>
				<div class="growthhack-f__sick">
					<div class="growthhack-f__sick--inner">
						<h3 class="growthhack-f__sick--title">
							こんなことに心当たりはありませんか？
						</h3>
						<ul class="growthhack-f__sick--list">
							<li class="growthhack-f__sick--item"><i class="sprite_growthhack-f icon01" /><span>原因不明の体調不良が時々起こる</span></li>
							<li class="growthhack-f__sick--item"><i class="sprite_growthhack-f icon01" /><span>雨の日はなんとなくだるい</span></li>
							<li class="growthhack-f__sick--item"><i class="sprite_growthhack-f icon01" /><span>台風がくると体調が悪くなる</span></li>
						</ul>
					</div>
					<i class="growthhack-f__sick--img sprite_growthhack-f img03" />
					<p class="growthhack-f__sick--text">それ、低気圧のせいかもしれません。</p>
				</div>
				<div class="growthhack-f__allright">
					<h3 class="growthhack-f__allright--title">
						<img src="@/assets/img/growthhack/about_intro_img02.png" alt="頭痛ーる" class="growthhack-f__allright--img"><span>があれば、もう大丈夫！</span>
					</h3>
					<p class="growthhack-f__allright--text">頭痛ーるは不調の原因となる「気圧変化」をお知らせします！</p>
				</div>
				<div class="growthhack-f__caution">
					<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
					<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
				</div>
				<div class="growthhack-f__with-zutool">
					<h2 class="growthhack-f__with-zutool--title">
						with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
					</h2>
					<i class="growthhack-f__with-zutool--img sprite_growthhack-f img02" />
				</div>
				<div class="about-rightnow__login">
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingSugotokuTopBtn04" class="about-rightnow__login--button">今すぐ利用する</a>
				</div>
			</section>
			<!-- / グロースハック案件F 機能説明をマルっと削除して見せ方を変える（別パターン）の設置箇所 -->

			<!-- マイページ登録限定スタンプ -->
			<sugotoku-mypage-stamp-link />
			<!-- /マイページ登録限定スタンプ -->

			<!-- 医師監修・頭痛ーる診断 -->
			<multiple-diagnosis-service id="js_scroll_shindan" :backendUrlProps="backendUrl" />
			<!-- /医師監修・頭痛ーる診断 -->

			<section class="about-column" id="js_scroll_column">
				<h2 class="about-column__title">頭痛ーるコラム</h2>
				<!-- NOTE: errorMessageに何か文言があればエラーとみなしてエラー文言表示 -->
				<p v-if="errorMessage !== ''" class="about-column__error"> {{ errorMessage }}</p>
				<ul v-else class="about-column__list">
					<li
						v-for="columnItem in columnList"
						:class="[
							'about-column__item',
							{ 'is-active': columnItem.isActive }
						]"
						:key="`columnItem-${columnItem.id}`"
					>
						<span
							@click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: columnItem.id } })"
							class="about-column__anchor"
							@touchstart="columnItem.isActive = true"
							@touchend="columnItem.isActive = false"
						>
							<figure class="about-column__thumbnail">
								<img v-if="columnItem.source_url" :src="`${columnItem.source_url}`" :alt="`${columnItem.alt_text}`" :class="{ 'is-ratio-height': columnItem.ratio }" />
								<img v-else src="@/assets/img/column_noimage.jpg" />
							</figure>
							<div class="about-column__contents">
								<h3 class="about-column__contents-title">{{ columnItem.title }}</h3>
								<span class="about-column__contents-readmore">続きを読む</span>
							</div>
						</span>
					</li>
				</ul>
				<span v-show="errorMessage === ''" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnTop` } })" class="about-column__seemore">もっと見る</span>
			</section>

			<section class="about-sick is-appdownload-margin">
				<div class="about-balloon">
					<div class="about-balloon__box">
						<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg01" /></div>
						<p class="about-balloon__text is-type01">気圧が変わるとどうして頭痛になるにゃ？</p>
					</div>
					<div class="about-balloon__img is-type03"><i class="about-balloon__bg sprite sprite-about_balloon_img03" /></div>
				</div>
				<div class="about-sick__illust">
					<p class="about-sick__illust--text is-appdownload-margin">
						体が気圧に合わせようとする際の<br>
						自律神経のストレス反応により不調に…
					</p>
					<img src="@/assets/img/about_sick_img01.png" alt="頭が痛い" class="about-sick__illust--img">
				</div>

				<div class="about-sick__symptoms-simple">
					<h2 class="about-sick__symptoms-simple--heading">他にもこんな症状が…</h2>
					<div class="about-sick__symptoms-simple--img"><i class="about-sick__symptoms-simple--bg sprite sprite-about_symptom_img01" /></div>
				</div>
				<div class="about-sick__inner is-appdownload-margin">
					<div class="about-balloon">
						<div class="about-balloon__img is-type04"><i class="about-balloon__bg sprite sprite-about_balloon_img04" /></div>
						<div class="about-balloon__box">
							<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg02" /></div>
							<p class="about-balloon__text is-type02">頭痛ーるを使えば、事前に症状が出そうな時期を確認して、痛みに備えることができるのじゃ。</p>
						</div>
					</div>
					<div class="about-balloon">
						<div class="about-balloon__box">
							<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg01" /></div>
							<p class="about-balloon__text is-type01">痛くなったら我慢しないで薬を飲んだり、身体を休めることも大切なのにゃ！</p>
						</div>
						<div class="about-balloon__img is-type01"><i class="about-balloon__bg sprite sprite-about_balloon_img01" /></div>
					</div>
				</div>
				<div class="about-rightnow__app-info">
					<p class="about-rightnow__login--text">体調の悪くなりそうな日をプッシュ通知でお知らせ</p>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingSugotokuTopBtn02" class="sugotoku-top__app-info--button">
						<img src="@/assets/img/sugotoku_app_info_btn02.png" alt="プレミアム スゴ得契約者ならアプリの有料機能使い放題 今すぐDL" />
					</a>
				</div>
			</section>

			<section class="about-function">
				<div class="about-function__inner">
					<div class="about-balloon">
						<div class="about-balloon__box">
							<div class="about-balloon__talk"><i class="about-balloon__bg sprite sprite-about_balloon_bg01" /></div>
							<p class="about-balloon__text is-type01">他にも便利な機能がたくさんあるので紹介するにゃ！</p>
						</div>
						<div class="about-balloon__img is-type05"><i class="about-balloon__bg sprite sprite-about_balloon_img05" /></div>
					</div>
					<ul class="about-function__list">
						<li class="about-function__item">
							<div class="about-function__intro">
								<h2 class="about-function__intro--heading">全国マップ</h2>
								<p class="about-function__intro--text">主要都市の気圧予報と<br>天気予報をチェック！</p>
								<div class="about-function__intro--img is-type01"><i class="about-function__intro--bg sprite sprite-about_balloon_img04" /></div>
							</div>
							<div class="about-function__device">
								<img src="@/assets/img/about_function_img01.png" alt="全国マップ" class="about-function__device--img">
							</div>
						</li>
						<li class="about-function__item">
							<div class="about-function__intro">
								<h2 class="about-function__intro--heading">痛み＆記録</h2>
								<p class="about-function__intro--text">アイコンをタップして<br>かんたん記録！</p>
								<div class="about-function__intro--img is-type02"><i class="about-function__intro--bg sprite sprite-about_balloon_img01" /></div>
							</div>
							<div class="about-function__device">
								<img src="@/assets/img/about_function_img02.png" alt="痛み＆記録" class="about-function__device--img">
							</div>
						</li>
						<li class="about-function__item">
							<div class="about-function__intro">
								<h2 class="about-function__intro--heading">痛みノート</h2>
								<p class="about-function__intro--text">一覧から痛みの傾向を<br>チェック！</p>
								<div class="about-function__intro--img is-type03"><i class="about-function__intro--bg sprite sprite-about_balloon_img06" /></div>
							</div>
							<div class="about-function__device">
								<img src="@/assets/img/about_function_img03.png" alt="痛みノート" class="about-function__device--img">
							</div>
						</li>
						<li class="about-function__item">
							<div class="about-function__intro">
								<h2 class="about-function__intro--heading">10回記録<br>チェック</h2>
								<p class="about-function__intro--text">痛みを10回記録して<br>あなたの痛みのタイプ<br>をチェック！</p>
								<div class="about-function__intro--img is-type04"><i class="about-function__intro--bg sprite sprite-about_balloon_img05" /></div>
							</div>
							<div class="about-function__device">
								<img src="@/assets/img/about_function_img04.png" alt="10回記録チェック" class="about-function__device--img">
							</div>
						</li>
					</ul>
					<div class="about-rightnow__login">
						<p class="about-rightnow__login--text">さっそく便利機能を使ってみよう！</p>
						<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button">今すぐ利用する</a>
					</div>
				</div>
			</section>

			<section class="about-character">
				<div class="about-character__inner">
					<h2 class="about-character__heading">頭痛ーるキャラクター紹介</h2>
					<ul class="about-character__list">
						<li class="about-character__item">
							<div class="about-character__visual is-type01">
								<i class="about-character__visual--img sprite sprite-about_character_img01" />
							</div>
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">ふくろう博士（ヒロシ）</h3>
								<p class="about-character__intro--text">気象予報士。男手ひとつで子猫のマロを育てている。</p>
							</div>
						</li>
						<li class="about-character__item">
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">マロ</h3>
								<p class="about-character__intro--text">ふくろう博士の養子。ある寒い雨の日にひとりで泣いているところを博士に保護された。</p>
							</div>
							<div class="about-character__visual is-type02">
								<i class="about-character__visual--img sprite sprite-about_character_img02" />
							</div>
						</li>
						<li class="about-character__item">
							<div class="about-character__visual is-type03">
								<i class="about-character__visual--img sprite sprite-about_character_img03" />
							</div>
							<div class="about-character__intro">
								<h3 class="about-character__intro--heading">てるてるネコ</h3>
								<p class="about-character__intro--text">謎のてるてる坊主。何故かいつもマロを見守っている。</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="about-rightnow__login">
					<p class="about-rightnow__login--text">SNSで話題！キャラクターたちの重すぎる<br>過去とは…？メニュー画面をチェック！</p>
					<a @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button">今すぐ利用する</a>
				</div>
			</section>

			<div class="about-footer">
				<!-- スゴ得コンテンツTOP用リンク -->
				<sugotoku-link />
			</div>

			<!-- 下に吸着するアプリダウンロードボタン -->
			<aside class="sticky-bottom-appdownload">
				<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingSugotokuTopStickyBtn01_iOS" class="sticky-bottom-appdownload--button">
					<img src="@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード" />
				</a>
			</aside>
		</article>
	</div>

	<!-- NOTE: いったんモーダル運用停止（月の満ち欠けカレンダー停止のため）。再開時はコメントアウトを外す。 -->
	<!-- スゴ得ポップアップ -->
	<!-- <sugotoku-popup :debug-date="debugDate" /> -->
	<!-- /スゴ得ポップアップ -->
</div>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_COLUMN_LIST } from '../store/modules/column/mutation-types'

// Compornents
import SugotokuTopBanner from '@/components/Organisms/SugotokuTopBanner'
// import SugotokuTopWeatherForecast from '@/components/Organisms/SugotokuTopWeatherForecast'
import SugotokuLink from '@/components/Atoms/SugotokuLink'
// import SugotokuPopup from '@/components/Organisms/SugotokuPopup' NOTE: いったんモーダル運用停止（月の満ち欠けカレンダー停止のため）。再開時はコメントアウトを外す。
import SugotokuMypageStampLink from '@/components/Organisms/SugotokuMypageStampLink'
import MultipleDiagnosisService from '@/components/Organisms/MultipleDiagnosisService'

export default {
	name: 'SugotokuTop',
	components: {
		SugotokuTopBanner,
		// SugotokuTopWeatherForecast,
		SugotokuLink,
		// SugotokuPopup, // NOTE: いったんモーダル運用停止（月の満ち欠けカレンダー停止のため）。再開時はコメントアウトを外す。
		SugotokuMypageStampLink,
		MultipleDiagnosisService
	},
	data () {
		return {
			columnList: null,
			backendUrl: cmnConst.BACKEND_URL,
			isShowHeadcheTypeCheck: false,
			errorMessage: '',
			infoDisplayFlag: false // NOTE: お知らせ表示フラグ
		}
	},
	props: {
		debugDate: {
			type: String,
			default: ''
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('column', ['columnListData'])
	},
	mounted () {
		// NOTE: お知らせ非表示判定（07月30日まで表示させる）
		const today = moment(new Date()).format()
		const endDay = moment(new Date('2025-02-28 23:59:59')).format()
		this.infoDisplayFlag = endDay >= today

		this.getColumnListData()

		// ページ遷移後のアンカーリンク
		// NOTE: 特集ページでも実装、今後統一する予定
		if (this.$route.hash) {
			this.$nextTick(() => {
				const $elem = document.querySelector(this.$route.hash)
				if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
			})
		}
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('column', [SET_COLUMN_LIST]),

		/**
		 * コラム一覧の取得処理
		 */
		getColumnListData () {
			this.SET_COLUMN_LIST({
				per_page: 3,
				page: 1,
				categories: ''
			}).then(res => {
				// TODO: this.columnListDataがエラー、length=0、nullだった場合に何も表示されない。errorMessageを表示するエラー処理が必要
				this.columnList = this.columnListData.map((item, index) => {
					return {
						'id': item.id,
						'title': item.title.rendered,
						'source_url': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['source_url'] : null,
						'alt_text': item['_embedded']['wp:featuredmedia'] ? item['_embedded']['wp:featuredmedia'][0]['alt_text'] : null,
						'ratio': item['_embedded']['wp:featuredmedia'] && Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['height']) > Number(item['_embedded']['wp:featuredmedia'][0]['media_details']['width']),
						'isActive': false
					}
				})
			}).catch(error => {
				// エラー処理
				console.log(error)
				// CHANGED: コラムAPIが失敗してもWEBアプリを利用できるようにするよう変更
				// this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=error`, type: 'appDownload' } })
				this.errorMessage = '記事を取得できませんでした'
			}).finally(() => {
				// コンテンツの高さをemitする処理
				// CHANGED: コラムAPIが失敗してもWEBアプリを利用できるようにするためthenからfinallyに処理を移した
				// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
				// this.contentHeight()
			})
		},

		/**
		 * 頭痛タイプ診断の開閉処理
		 */
		onAfterEnter (elem) {
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)

			// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
			// this.contentHeight()
		},

		// コンテンツの高さをemitする処理（実処理は親で行う）
		// NOTE: 現在、未使用
		contentHeight () {
			this.$nextTick(() => {
				// TODO: 表示が崩れるため、一旦「setTimeout」で対応
				setTimeout(() => {
					const domContainer = document.getElementById('js_sugotoku-top_container')
					if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
				}, 1000)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: iOSトップページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-30: 1.875rem;
$sugotoku-app-download-red: #cb0034;
$sugotoku-app-download-green: #53712B;

.sugotoku-top {
	&-container {
		width: 100%;
		height: 100%;
		// overflow: auto;
		overflow: visible;	// NOTE: 親要素も含めoverflow: visible以外が指定されているとページ下部に吸着させるstickyが効かなくなるため変更
		background: $_white;
	}

	&__app-info {
		margin: 0 auto;
		padding: 0;

		&.is-banner-top {
			margin-top: $spacing-20;
		}

		&--text {
			color: $sugotoku-app-download-red;
			font-weight: bold;
			text-align: center;
			margin: $spacing-20 0 $spacing-6;
		}

		&--link,
		&--notice {
			display: block;
			// color: $about-caption-link;
			padding: $spacing-16 0 0;
			margin: 0 $spacing-10;
			// text-decoration: underline;
			transform: rotate(0.05deg);

			& > a { color: $primary; }
		}
		&--link { text-align: center; }
		&--notice { text-align: left; }

		&--button {
			display: block;
			width: 88%;
			margin: 0 auto;
			cursor: pointer;

			& > img {
				width: 100%;
				vertical-align: middle;
			}
		}
	}

	// CHANGE: 共通で使用するスプライト画像sprite_appdownload.pngのCSSについてはcommon.scssで統一するよう変更
}

.headache-type-check__wrapper {
	width: 100%;
	margin: $spacing-20 0;

	&__title {
		margin: 0;
		text-align: center;

		img {
			width: 100%;
			max-width: 300px;
		}
	}
}

// トランジション
.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

</style>
